// App.js

import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import pageOneImage1 from './images/pageOne.jpg'
import heart from './images/heart-png-38780.png'
import pageTwoImage1 from './images/pageTwo1.jpg'
import pageTwoImage2 from './images/pageTwo2.jpg'
import pageThreeImage1 from './images/pageThree1.jpg'
import pageThreeImage2 from './images/pageThree2.jpg'
import pageThreeImage3 from './images/pageThree3.jpg'
import pageFourImage1 from './images/pageFour1.jpg'
import pageFiveImage1 from './images/pageFive1.jpg'
import pageFiveImage2 from './images/pageFive2.jpg'
import pageFiveImage3 from './images/pageFive3.jpg'
import tix from './images/city.png'

const LandingPage = () => {
  return (
    <div className="landing-page">
      <h1 className="greeting">Hi, Kaitlyn...</h1>
      <Link to="/pageOne">
        <button className="enter-site-button">ENTER SITE</button>
      </Link>
    </div>
  );
};

const PageOne = () => {
  return (
    <div className="page-one">
      <h1>Lets take a trip</h1>
      <h1>down memory lane,</h1>
      <h1>shall we?</h1>
      <Link to="/pageTwo">
        <button className="enter-site-button">Okay...</button>
      </Link>
    </div>
  );
};

const PageTwo = () => {
  return (
    <div className="page-one">
      <h2>Here's where it all started...</h2>
      <div className="image-row">
        <img src={heart} alt="imageOne" />
        <img src={pageOneImage1} alt="imageTwo" />
        <img src={heart} alt="imageThree" />
      </div>
      <Link to="/pageThree">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageThree = () => {
  return (
    <div className="page-one">
      <h1>Through the ups</h1>
      <h1>and downs...</h1>
      <div className="image-row">
        <img src={pageTwoImage1} alt="imageOne" />
        <img src={pageTwoImage2} alt="imageTwo" />
      </div>
      <Link to="/pageFour">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageFour = () => {
  return (
    <div className="page-one">
      <h1>We've done some</h1>
      <h1>incredible things...</h1>
      <div className="image-row">
        <img src={pageThreeImage1} alt="imageOne" />
        <img src={pageThreeImage2} alt="imageTwo" />
        <img src={pageThreeImage3} alt="imageThree" />
      </div>
      <Link to="/pageFive">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageFive = () => {
  return (
    <div className="page-one">
      <h2>and jumped some hurdles</h2>
      <h2>along the way..</h2>
      <div className="single-image">
        <img src={pageFourImage1} alt="imageOne" />
      </div>
      <Link to="/pageSix">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageSix = () => {
  return (
    <div className="page-one">
      <h1>To get where we are now...</h1>
      <div className="image-row">
        <img src={pageFiveImage1} alt="imageOne" />
        <img src={pageFiveImage2} alt="imageTwo" />
        <img src={pageFiveImage3} alt="imageThree" />
      </div>
      <Link to="/pageSeven">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageSeven = () => {
  return (
    <div className="page-one">
      <h1>So,</h1>
      <h1>the question that</h1>
      <h1>still remains</h1>
      <h1>is</h1>
      <Link to="/pageEight">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageEight = () => {

  const handleNoButtonClick = () => {
    // Display a funny error message (you can customize this message)
    alert("Oops! The Option is no available! HEHEHEHEHEHE");
  };

  return (
    <div className="page-one">
      <div className="single-image">
        <img src={'https://i.pinimg.com/originals/5d/05/53/5d0553010ad8d2b6f71d39eff21996a6.gif'} alt="gif" />
      </div>
      <div className="button-row">
        <Link to="/pageNine">
          <button className="yes-button">Yes</button>
        </Link>
        <button
          className='no-button'
          onClick={handleNoButtonClick}
        >
          No
        </button>
      </div>
    </div>
  );
};

const PageNine = () => {
  return (
    <div className="page-one">
      <h1>WOOOOOOOOOOOOOOOOOOOOOOOOOOOO</h1>
      <h1>OOOOOOOOOOOOOOOOOOOOOOOOOOOOO</h1>
      <h1>OOOOOOOOOOOOOOOOOOOOOOOOOOOOO</h1>
      <h1>OOOOOOOOOOOOOOOOOOOOOOOOOOOOO</h1>
      <h1>OOOOOOOOOOOOOOOOOOOOOOOOOOOO!!!</h1>
      <Link to="/pageTen">
        <button className="enter-site-button">CONTINUE</button>
      </Link>
    </div>
  );
};

const PageTen = () => {
  return (
    <div className="page-one">
      <h2>YOU WIN A PRIZE!</h2>
      <Link to="/pageEleven">
        <button className="enter-site-button">ACCEPT PRIZE</button>
      </Link>
    </div>
  );
};

const PageEleven = () => {
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);

  const handlePlayClick = () => {
    const audioElement = document.getElementById('backgroundAudio');

    if (!audioIsPlaying) {
      audioElement.play();
      setAudioIsPlaying(true);
    }
  };

  return (
    <div className="page-one">
      <h2>A Ticket with your valentine</h2>
      <h2>to see the band who helped </h2>
      <h2>start it all!</h2>
      <div className="single-image">
        <img src={tix} alt="imageOne" />
      </div>
      <button onClick={handlePlayClick}>Play Audio</button>
      <audio id="backgroundAudio" autoPlay loop>
        <source src='/music/thegirl.mp3' type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pageOne" element={<PageOne />} />
        <Route path="/pageTwo" element={<PageTwo />} />
        <Route path="/pageThree" element={<PageThree />} />
        <Route path="/pageFour" element={<PageFour />} />
        <Route path="/pageFive" element={<PageFive />} />
        <Route path="/pageSix" element={<PageSix />} />
        <Route path="/pageSeven" element={<PageSeven />} />
        <Route path="/pageEight" element={<PageEight />} />
        <Route path="/pageNine" element={<PageNine />} />
        <Route path="/pageTen" element={<PageTen />} />
        <Route path="/pageEleven" element={<PageEleven />} />
      </Routes>
    </Router>
  );
};

export default App;
